form {
    margin-block-end: unset;
}

#sso-app {
    min-width: 320px;
    min-height: 100%;
    overflow: auto;

    background-color: #fff;
}

.grecaptcha-badge {
    display: none !important;
}

/* react perfect scrollbar overrides */
.ps__rail-y {
    left: auto !important;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-x.ps--clicking {
    background-color: transparent;
}

.oneai-widget {
    left: unset !important;
    right: 16px !important;
    bottom: 16px !important;
    max-width: calc(100% - 32px) !important;
}
